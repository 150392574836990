$primary: #3b23b2;
$blue60: #3874b4;
$blue300: #5096f1;
$blue80: #26589e;
$gray50: #c2c2c2;
$gray100: #92989a;
$gray217: #d9d9d9;
$gray239: #efefef;
$gray300: #efefef;
$gray400: #a1a1a1;
$disabled: #999999;
$selected: #cdd5e9;
$red: #ff3737;
$lightRed: #ffe1e1;
$white: #fff;

$headerColor: #00CC44;
@if ($env == 'prod') {
  $headerColor: $blue60;
} @else if ($env == 'stg') {
  $headerColor: orange;
}